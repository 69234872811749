.TopbarPopupMenuContainer1 {
	width: 200px;
	position: absolute;
	background: #fff;
	border: 1px solid #d0d0d0;
	padding: 20px;
	top: 65px;
	left: calc(100vw - (100vw - 100%) - 246px);
	z-index: 9;
}

.TopbarPopupMenuArrow {
	top: -8px;
	right: 20px;
	background-color: #fff;
	width: 10px;
	height: 10px;
	margin: 0 0 0 0px;
	position: absolute !important;
	border-left: 1px solid #d0d0d0;
	border-top: 1px solid #d0d0d0;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.TopbarPopupMenuEmailText {
	position: relative;
	top: -8px;
	color: grey;
}

.TopbarPopupMenuLogoutButton {
  background-color: #0095ff;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  width: 200px;
}

.TopbarPopupMenuLogoutButton:hover,
.TopbarPopupMenuLogoutButton:focus {
  background-color: #07c;
}

.TopbarPopupMenuLogoutButton:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.TopbarPopupMenuLogoutButton:active {
  background-color: #0064bd;
  box-shadow: none;
}

.TopbarPopupMenuAccountButton {
  background-color: #f5f5f5;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #0095ff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  width: 200px;
}

.TopbarPopupMenuAccountButton:hover,
.TopbarPopupMenuAccountButton:focus {
  background-color: #e0e0e0;
}

.TopbarPopupMenuAccountButton:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.TopbarPopupMenuAccountButton:active {
  background-color: #0064bd;
  box-shadow: none;
}

.TopbarPopupMenuFlagContainter {
	display: flex;
	flex-direction: row;
    margin-left: auto;
    margin-right: auto;
	width: fit-content;
}

.TopbarPopupMenuFlagItem {
    margin-left: 10px;
    margin-right: 10px;
	cursor: pointer;
}
