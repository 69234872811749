.App {
  text-align: center;
}

.AppLogo {
}

.ListContainer1 {
	width: 100vw;
    margin-left: auto;
    margin-right: auto;
}

.ListContainer2 {
	display: table;
    padding: 5px;
	overflow-y: scroll;
	list-style: none;
	left: 0;
	bottom: 0;
	overflow: hidden;
	margin: auto;
}

.ListContainer3 {
	width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.ListRow {
	display: flex-row;
}

.ListHeader {
    display: table-cell;
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 20vw;
	text-align: left;
}

.ListLink {
    display: table-cell;
	color: black;
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
    width: 20vw;
	text-align: left;
}

.ListText {
    display: table-cell;
	font-size: 20px;
    padding: 5px;
    width: 20vw;
}

.ListPrevNextLink {
    display: table-cell;
	color: black;
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
    width: 120px;
}

.ListPrevNextLinkDisabled {
    display: table-cell;
	color: grey;
	font-size: 20px;
    padding: 5px;
    width: 120px;
}

.ViewRowContainer {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	border-style: solid;
	border-width: 1px 0px 1px 0px;
	border-color: white;
}

.ViewRowContainer:hover {
	background-color: #fafafa;
	cursor: pointer;
	border-color: #aaaaaa;
}

.ViewRowTitle {
	font-size: 12px;
	font-weight: bold;
    padding: 10px;
	margin-left: 15px;
	flex: 0.3;
	min-width: 120px;
	color: grey;
	text-align: left;
	text-transform: uppercase;
}

.ViewRowText {
	font-size: 20px;
    padding: 10px;
	flex: 0.5;
	text-align: left;
}

.ViewRowGreater {
	font-family: sans-serif;
	font-size: 20px;
	font-weight: bold;
    padding: 10px;
	color: grey;
	flex: 0.15;
	text-align: right;
}

.ViewContainer1 {
	width: 100vw;
    margin-left: auto;
    margin-right: auto;
}

.ViewContainer2 {
	width: 500px;
    margin-left: 20px;
}

.ViewTopTitle {
	font-size: 30px;
    padding: 5px;
}

.ViewTopText {
	font-size:16px;
    padding: 5px;
	color: grey;
}

.ViewBlockContainer {
    width: 50vw;
	min-width: 500px;
    margin-left: auto;
    margin-right: auto;
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 10px;
}

.ViewBlockTitle {
	font-size: 24px;
    padding: 5px;
	margin-left: 15px;
	text-align: left;
}

.ViewContainerButtons {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
}

.ViewSaveButton {
  background-color: #0095ff;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.ViewSaveButton:hover,
.ViewSaveButton:focus {
  background-color: #07c;
}

.ViewSaveButton:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.ViewSaveButton:active {
  background-color: #0064bd;
  box-shadow: none;
}

.ViewCancelButton {
  background-color: #f5f5f5;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #0095ff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.ViewCancelButton:hover,
.ViewCancelButton:focus {
  background-color: #e0e0e0;
}

.ViewCancelButton:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.ViewCancelButton:active {
  background-color: #0064bd;
  box-shadow: none;
}


.ViewContainer {
	width: 100vw;
    margin-left: auto;
    margin-right: auto;
}

.ViewItemContainer {
	width: 30vw;
    margin-left: 20px;
}

.ViewItemFieldset {
	border-color: grey; 
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 6px;
	box-sizing: border-box; 
	grid-area: 1 / 1; /* first row, first column */
}

.ViewItemLegend {
	font-size: 12px;
	color: grey;
	text-align: left;
	padding-left: 5px;
	padding-right: 5px;
}

.ViewItemInput {
	border: 0px;
	font-size: 16px;
	width: 29vw;
}

.ViewItemInput:focus {
    outline: none;
}

.LoginBlockContainer {
    width: 35vw;
	min-width: 400px;
    margin-left: auto;
    margin-right: auto;
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 10px;
}

.MyInfoTopTitle {
	font-size: 30px;
    padding: 5px;
}

.MyInfoTopText {
	font-size:16px;
    padding: 5px;
	color: grey;
}

.MyInfoBlockContainer {
    width: 50vw;
	min-width: 500px;
    margin-left: auto;
    margin-right: auto;
	border-style: solid;
	border-width: 1px;
	border-color: #aaaaaa;
	border-radius: 10px;
}

.MyInfoBlockTitle {
	font-size: 24px;
    padding: 5px;
	margin-left: 15px;
	text-align: left;
}

.MyInfoRowContainer {
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	border-style: solid;
	border-width: 1px 0px 1px 0px;
	border-color: white;
}

.MyInfoRowContainer:hover {
	background-color: #fafafa;
	cursor: pointer;
	border-color: #aaaaaa;
}

.MyInfoRowTitle {
	font-size: 12px;
	font-weight: bold;
    padding: 10px;
	margin-left: 15px;
	flex: 0.3;
	color: grey;
	text-align: left;
	text-transform: uppercase;
}

.MyInfoRowText {
	font-size: 20px;
    padding: 10px;
    //width: 20vw;
	flex: 0.5;
	text-align: left;
}

.MyInfoRowGreater {
	font-family: sans-serif;
	font-size: 20px;
	font-weight: bold;
    padding: 10px;
	color: grey;
	flex: 0.15;
	text-align: right;
}

